/**
 * Translate description: Input serch with ajax
 */

import { useRef, useState, useEffect } from 'react';


import Autocomplete from '@mui/joy/Autocomplete';
import CircularProgress from '@mui/joy/CircularProgress';

import { useLanguageContext } from "../context/LanguageContext";

import axios from 'axios';

export function InputSearchAjax(props = {}) {
  const timerRef = useRef();
  const { t } = useLanguageContext();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');

  const { exclude, debouncetimeout, minLength, url, method, lang_id, ...rest } = props;

  useEffect(() => {
    loadResults(1, lang_id, method);
  }, [search, lang_id, method]);

  const loadResults = (page = 1, lang, type = 'post') => {
    if (loading || search.length < (minLength || 3)) return setItems([]);
    setLoading(true);

    if (type === 'post') {
      const filter = { search }
      if (lang) filter.lang = { filter_type: 'only', lang_id: lang_id.toString() }

      axios.post(`${process.env.REACT_APP_TMBACK_URL}/api${url}`, { filter }, { withCredentials: true })
        .then(
          (res) => {
            if(exclude) res.data.data = res.data.data.filter(v => v.id != exclude);
            setItems(res.data.data);
            setLoading(false);
          },
          (error) => {
            setError(error);
            setLoading(false);
          }
        )
    } else {
      axios.get(`${process.env.REACT_APP_TMBACK_URL}/api${url}?search=${search}`, { withCredentials: true })
        .then(
          (res) => {
            if(exclude) res.data.data = res.data.data.filter(v => v.id != exclude);
            setItems(res.data.data);
            setLoading(false);
          },
          (error) => {
            setError(error);
            setLoading(false);
          }
        )
    }
  }

  const debouncedSearch = (inputValue) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setSearch(inputValue);
    }, debouncetimeout ?? 500);
  };


  const filterOptions = (options, { inputValue }) => {
    debouncedSearch(inputValue);
    return items;
  };

  return (
    <Autocomplete
      {...props}
      options={items}
      getOptionLabel={(option) => option.title || option.name || option.login || option.toString() || ''}
      placeholder={t('Type min 3 characters')}
      endDecorator={
        loading ? (
          <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
        ) : null
      }
      filterOptions={filterOptions}
      readOnly={loading}
    />
  );
}

