/**
 * Translate description: Table component for banners
 */

import { Fragment, useState, useContext } from 'react';
import { Link as ReactLink } from 'react-router-dom';

import { useLanguageContext } from "../../../context/LanguageContext";
import AppContext from '../../../context/AppContext';

import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';

import Filters from './Filters';
import Pagination from '../../../components/layout/Pagination';
import ItemEditDelete from '../../../components/layout/ItemEditDelete';
import TableHeaderSort from '../../../components/layout/TableHeaderSort';
import { Loading } from '../../../components/loading';

import { capitalizeFirstLetter } from '../../../utils';



export function BannerTable() {
  const { t } = useLanguageContext();
  const [selected, setSelected] = useState([]);
  const bannerplaces = {
    'top': t('After Slider Desktop'),
    'top-mobile': t('After Slider Mobile'),
    'aside': t('Sidebar Desktop'),
    'mosaic': t('Mosaic Home Desktop'),
    'mosaic-mobile': t('Mosaic Home Mobile'),
    'publication': t('Publication Desktop'),
    'publication-mobile': t('Publication Mobile'),
    'footer': t('Before Footer Desktop'),
    'footer-mobile': t('Before Footer Mobile'),
  };

  const { listItems } = useContext(AppContext);
  const { paginator, setPaginator } = useContext(AppContext);
  const { sorting, setSorting } = useContext(AppContext);
  const { filter, setFilter } = useContext(AppContext);
  const { setModal } = useContext(AppContext);
  const { loading } = useContext(AppContext);

  return (
    <Fragment>
      <Filters
        setPaginator={setPaginator}
        filter={filter}
        setFilter={setFilter}
      />
      {selected.length ? (
        <Box>
          Выбрано элементов: {selected.length}
        </Box>
      ) : (<></>)}

      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== listItems?.length
                  }
                  checked={selected.length === listItems?.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? listItems?.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === listItems?.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th>
              <th style={{ width: 50, padding: '12px 6px' }}>
                <TableHeaderSort
                  sorting={sorting}
                  setSorting={setSorting}
                  sortBy="id"
                >
                  {t('ID')}
                </TableHeaderSort>
              </th>
              <th style={{ width: 150, padding: '12px 6px' }} >
                <TableHeaderSort
                  sorting={sorting}
                  setSorting={setSorting}
                  sortBy="name"
                >
                  {t('Name')}
                </TableHeaderSort>
              </th>
              <th style={{ width: 200, padding: '12px 6px' }} >
                <TableHeaderSort
                  sorting={sorting}
                  setSorting={setSorting}
                  sortBy="status_id"
                >
                  {t('Status')}
                </TableHeaderSort>
              </th>
              <th style={{ width: 100, padding: '12px 6px' }} >
                <TableHeaderSort
                  sorting={sorting}
                  setSorting={setSorting}
                  sortBy="place"
                >
                  {t('Place')}
                </TableHeaderSort>
              </th>
              <th style={{ width: 100, padding: '12px 6px' }}> </th>
            </tr>
          </thead>
          <tbody>
            {loading ? <tr><td colSpan={6}><Loading /></td></tr> : null}
            {(Array.isArray(listItems) && !loading && listItems?.length) ? (
              listItems.map((row) => (
                <tr key={row.id}>
                  <td style={{ textAlign: 'center', width: 120 }}>
                    <Checkbox
                      size="sm"
                      checked={selected.includes(row.id)}
                      color={selected.includes(row.id) ? 'primary' : undefined}
                      onChange={(event) => {
                        setSelected((ids) =>
                          event.target.checked
                            ? ids.concat(row.id)
                            : ids.filter((itemId) => itemId !== row.id),
                        );
                      }}
                      slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                      sx={{ verticalAlign: 'text-bottom' }}
                    />
                  </td>
                  <td>
                    <Typography level="body-xs">{row.id}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">
                      <ReactLink to={`/frontend/banner/${row.id}`} style={{ textDecoration: 'none' }}>
                        <Typography color='primary'>
                          {row.name}
                        </Typography>
                      </ReactLink>
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{t(capitalizeFirstLetter(row.status_name))}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{bannerplaces[row.place] ?? t(row.place)}</Typography>
                  </td>


                  <td style={{ textAlign: 'right' }}>
                    <ItemEditDelete
                      id={row.id}
                      editLink='frontend/banner'
                      deleteLink='banners'
                      setModal={setModal}
                    />
                  </td>
                </tr>
              ))
            ) : null}
            {listItems?.length === 0 && !loading ? (
              <tr><td colSpan={6}><Typography level="body-lg" sx={{ textAlign: 'center' }}>{t('No data')}</Typography></td></tr>
            ) : null}
          </tbody>
        </Table>
      </Sheet>
      <Pagination paginator={paginator} setPaginator={setPaginator} />
    </Fragment >
  );
}