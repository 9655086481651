import { Crud } from ".";

export class BannersApi extends Crud {
    constructor() {
        super('banners')
    }
    async status(id, status_id) {
        let body = await this.query(`status/${id}`, 'put', { status_id })
        return body
    }
}