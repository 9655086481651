/**
 * Translate description: Sidebar admin-menu
 */

import { useState, Fragment, useEffect } from 'react';
import { useLocation, Link as ReactLink } from "react-router-dom";
import { useLanguageContext } from "../../context/LanguageContext";

import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import WebRoundedIcon from '@mui/icons-material/WebRounded';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import TranslateIcon from '@mui/icons-material/Translate';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import TagRoundedIcon from '@mui/icons-material/TagRounded';
import RssFeedRoundedIcon from '@mui/icons-material/RssFeedRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function locateIn(location, regexarr) {
  return !!regexarr.filter(v => {
    const regexp = new RegExp(`^${v}$`);
    return regexp.test(location.pathname);
  }).length;
}

function Toggler({ defaultExpanded = false, locationWhereOpened = [], renderToggle, children }) {
  const [open, setOpen] = useState(defaultExpanded);
  useEffect(() => {
    setOpen(locateIn(location, locationWhereOpened));
  }, [location.pathname]);

  return (
    <Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </Fragment>
  );
}

export default function SidebarMenu() {
  const { t } = useLanguageContext();
  const location = useLocation();

  const linkStyle = { textDecoration: 'none', display: 'block', width: '100%' };

  return (
    <>
      <List
        size="sm"
        sx={{
          gap: 1,
          '--List-nestedInsetStart': '30px',
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
        }}
      >

        {/* <ListItem>
        <ReactLink to="/" style={linkStyle}>
          <ListItemButton selected={location.pathname === '/'}>
            <HomeRoundedIcon />
            <ListItemContent>
              <Typography level="title-sm">{t("Home")}</Typography>
            </ListItemContent>
          </ListItemButton>
        </ReactLink>
      </ListItem> */}

        <ListItem nested>
          <Toggler
            locationWhereOpened={['/news', '/news-categories', '/news/\\d+', '/news-category/\\d+']}
            renderToggle={({ open, setOpen }) => (
              <ListItemButton onClick={() => setOpen(!open)}>
                <FeedOutlinedIcon />
                <ListItemContent>
                  <Typography level="title-sm">{t("News")}</Typography>
                </ListItemContent>
                <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
              </ListItemButton>
            )}
          >
            <List sx={{ gap: 0.5 }}>
              <ListItem sx={{ mt: 0.5 }}>
                <ReactLink to="/news" style={linkStyle}>
                  <ListItemButton selected={locateIn(location, ['/news', '/news/\\d+'])}>{t("All news")}</ListItemButton>
                </ReactLink>
              </ListItem>
              <ListItem>
                <ReactLink to="/news-categories" style={linkStyle}>
                  <ListItemButton selected={locateIn(location, ['/news-categories', '/news-category/\\d+'])}>{t("Categories")}</ListItemButton>
                </ReactLink>
              </ListItem>
            </List>
          </Toggler>
        </ListItem>

        <ListItem nested>
          <Toggler
            locationWhereOpened={['/posts', '/post-categories', '/post/\\d+', '/post-category/\\d+']}
            renderToggle={({ open, setOpen }) => (
              <ListItemButton onClick={() => setOpen(!open)}>
                <NewspaperOutlinedIcon />
                <ListItemContent>
                  <Typography level="title-sm">{t("Posts")}</Typography>
                </ListItemContent>
                <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
              </ListItemButton>
            )}
          >
            <List sx={{ gap: 0.5 }}>
              <ListItem sx={{ mt: 0.5 }}>
                <ReactLink to="/posts" style={linkStyle}>
                  <ListItemButton selected={locateIn(location, ['/posts', '/post/\\d+'])}>{t("All posts")}</ListItemButton>
                </ReactLink>
              </ListItem>
              <ListItem>
                <ReactLink to="/post-categories" style={linkStyle}>
                  <ListItemButton selected={locateIn(location, ['/post-categories', '/post-category/\\d+'])}>{t("Categories")}</ListItemButton>
                </ReactLink>
              </ListItem>
            </List>
          </Toggler>
        </ListItem>

        <ListItem>
          <ReactLink to="/pages" style={linkStyle}>
            <ListItemButton selected={location.pathname === '/pages'}>
              <AutoStoriesRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">{t("Pages")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem>

        <ListItem>
          <ReactLink to="/tags" style={linkStyle}>
            <ListItemButton selected={locateIn(location, ['/tags', '/tag/\\d+'])}>
              <StyleOutlinedIcon />
              <ListItemContent>
                <Typography level="title-sm">{t("Tags")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem>

        <ListItem nested>
          <Toggler
            locationWhereOpened={['/strings-admin', '/strings-front']}
            renderToggle={({ open, setOpen }) => (
              <ListItemButton onClick={() => setOpen(!open)}>
                <TranslateIcon />
                <ListItemContent>
                  <Typography level="title-sm">{t("Strings l10n")}</Typography>
                </ListItemContent>
                <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
              </ListItemButton>
            )}
          >
            <List sx={{ gap: 0.5 }}>
              <ListItem sx={{ mt: 0.5 }}>
                <ReactLink to="/strings-front" style={linkStyle}>
                  <ListItemButton selected={location.pathname === '/strings-front'}>{t("Front strings")}</ListItemButton>
                </ReactLink>
              </ListItem>
              <ListItem>
                <ReactLink to="/strings-admin" style={linkStyle}>
                  <ListItemButton selected={location.pathname === '/strings-admin'}>{t("Admin strings")}</ListItemButton>
                </ReactLink>
              </ListItem>
            </List>
          </Toggler>
        </ListItem>

        {/* <ListItem>
          <ReactLink to="/rss" style={linkStyle}>
            <ListItemButton selected={location.pathname === '/rss'}>
              <RssFeedRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">{t("RSS")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem> */}

        <ListItem nested>
          <Toggler
            locationWhereOpened={['/frontend/menus', '/frontend/banners', '/frontend/mainslider', '/frontend/lifestyle-mosaic']}
            renderToggle={({ open, setOpen }) => (
              <ListItemButton onClick={() => setOpen(!open)}>
                <ViewQuiltRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">{t("Frontend")}</Typography>
                </ListItemContent>
                <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
              </ListItemButton>
            )}
          >
            <List sx={{ gap: 0.5 }}>
              <ListItem>
                <ReactLink to="/frontend/menus" style={linkStyle}>
                  <ListItemButton selected={location.pathname === '/frontend/menus'}>{t("Menus")}</ListItemButton>
                </ReactLink>
              </ListItem>
              <ListItem>
                <ReactLink to="/frontend/banners" style={linkStyle}>
                  <ListItemButton selected={location.pathname === '/frontend/banners'}>{t("Banners")}</ListItemButton>
                </ReactLink>
              </ListItem>
              <ListItem>
                <ReactLink to="/frontend/mainslider" style={linkStyle}>
                  <ListItemButton selected={location.pathname === '/frontend/mainslider'}>{t("Main slider")}</ListItemButton>
                </ReactLink>
              </ListItem>
              <ListItem>
                <ReactLink to="/frontend/lifestyle-mosaic" style={linkStyle}>
                  <ListItemButton selected={location.pathname === '/frontend/lifestyle-mosaic'}>{t("Mosaic LifeStyle")}</ListItemButton>
                </ReactLink>
              </ListItem>
            </List>
          </Toggler>
        </ListItem>
      </List>

      <List
        size="sm"
        sx={{
          mt: 'auto',
          flexGrow: 0,
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
          '--List-gap': '4px',
          mb: 2,
        }}
      >
        {/* <ListItem>
          <ReactLink to="/support" style={linkStyle}>
            <ListItemButton selected={location.pathname.indexOf('/support') === 0}>
              <SupportRoundedIcon />
              {t("Support")}
            </ListItemButton>
          </ReactLink>
        </ListItem> */}


        <ListItem>
          <ReactLink to="/users" style={linkStyle}>
            <ListItemButton selected={location.pathname === '/users'}>
              <PeopleAltRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">{t("Users")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem>

        <ListItem>
          <ReactLink to="/settings" style={linkStyle}>
            <ListItemButton selected={location.pathname.indexOf('/settings') === 0}>
              <SettingsRoundedIcon />
              {t("Settings")}
            </ListItemButton>
          </ReactLink>
        </ListItem>
      </List>
    </>
  )
}