import axios from 'axios';

export const uploadImage = (file) => {

  const formData = new FormData();
  formData.append('file', file);

  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_TMBACK_URL}/api/images/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    }).then(response => {
      resolve(response.data?.filename ?? null);
    }).catch(error => {
      console.error('Ошибка загрузки: ', error.message);
      reject(error);
    });
  });
}
export function tmpToPreview(tmp_url) {
  let imagepath = process.env.REACT_APP_TMBACK_URL + '/api/images/preview/' + tmp_url.replace('uploadstmp/', '');
  return imagepath
}