import { Fragment, useContext } from 'react';
import Avatar from '@mui/joy/Avatar';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

import AppContext from '../context/AppContext';

function renderValue(option) {
  if (!option) return null;
  return (
    <Fragment>
      <ListItemDecorator sx={{ "--ListItemDecorator-size": "25px" }}>
        <Avatar sx={{ "--Avatar-size": "20px" }} src={`/img/lng/${option.value}.png`} />
      </ListItemDecorator>
      {option.label}
    </Fragment>
  );
}
export const SelectSourceLang2 = ({
  languages,
  currentLang,
  setCurrentLang,
  disabled
}) => {

  return (
    <Select
      disabled={disabled || false}
      slotProps={{
        listbox: {
          sx: {
            '--ListItemDecorator-size': '44px',
          },
          placement: 'bottom-start'
        },
      }}
      sx={{
        '--ListItemDecorator-size': '44px',
        minWidth: '250px',
        width: { xs: '100%', sm: 'initial' }
      }}
      renderValue={renderValue}
      onChange={(e, v) => setCurrentLang(v)}
      value={currentLang}
      size="sm"
    >
      {Object.keys(languages).map((lng) => (
        <Fragment key={languages[lng].name}>
          <Option value={languages[lng].code} label={languages[lng].name}>
            <ListItemDecorator sx={{ "--ListItemDecorator-size": "35px" }}>
              <Avatar sx={{ "--Avatar-size": "25px" }} src={languages[lng].image} />
            </ListItemDecorator>
            {languages[lng].name}
          </Option>
        </Fragment>
      ))}
    </Select>
  );
};


const SelectSourceLang = () => {
  const { languages, currentLang, setCurrentLang } = useContext(AppContext);
  return (
    <Select
      slotProps={{
        listbox: {
          sx: {
            '--ListItemDecorator-size': '44px',
          },
          placement: 'bottom-start'
        },
      }}
      sx={{
        '--ListItemDecorator-size': '44px',
        minWidth: '200px',
        width: { xs: '100%', sm: 'initial' }
      }}
      renderValue={renderValue}
      onChange={(e, v) => {
        localStorage.setItem("i18nextSrcLng", v);
        console.log(v);
        setCurrentLang(v);
      }}
      value={currentLang}
    >
      {Object.keys(languages).map((lng) => (
        <Fragment key={languages[lng].name}>
          <Option value={languages[lng].code} label={languages[lng].name}>
            <ListItemDecorator sx={{ "--ListItemDecorator-size": "35px" }}>
              <Avatar sx={{ "--Avatar-size": "25px" }} src={languages[lng].image} />
            </ListItemDecorator>
            {languages[lng].name}
          </Option>
        </Fragment>
      ))}
    </Select>
  );
};

export default SelectSourceLang;