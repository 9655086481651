
const { useState } = require("react");

import { useLanguageContext } from '../context/LanguageContext';

import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { Box, styled } from "@mui/joy";
import Button from "@mui/joy/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/joy/IconButton';

import { tmpToPreview, uploadImage } from '../utils/fetchImages';

import { useInfo } from '../utils/hooks/useInfo';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;



export function UploadImage(props) {
  const [uploading, setUploading] = useState(false)
  const { t } = useLanguageContext();
  let { value, onChange, onError } = props;
  const info = useInfo();

  return <Box sx={{ display: 'flex', alignItems: 'center', gap: 'var(--Input-paddingInline)' }}>
    <Button
      component="label"
      role={undefined}
      tabIndex={-1}
      variant="outlined"
      color="neutral"
      startDecorator={<FileUploadRoundedIcon />}
      disabled={uploading}
      sx={value ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}}
      style={{
        height: 50
      }}
    >
      {t('Upload a file')}
      <VisuallyHiddenInput type="file" onChange={async (e) => {
        setUploading(true);
        try {
          let tmp = await uploadImage(e.target.files[0]);
          let imagepath = tmpToPreview(tmp)
          onChange(null, imagepath);
          // onError(null);
        } catch (error) {
          console.error('Ошибка загрузки: ', error);
          // onError({ message: t('Error uploading image or invalid file type') });
          info(t('Error uploading image or invalid file type'), 'danger');
          onChange(null, '');
        }
        setUploading(false);
      }} />
    </Button >
    {value && <IconButton
      style={{
        height: 50,
        width: 50
      }}
      color="danger"
      variant="solid"
      onClick={() => {
        onChange(null, '');
      }}
      sx={{ borderRadius: 0 }}
    >
      <DeleteIcon sx={{ fontSize: '1.5rem' }} />
    </IconButton>}
    {value && <img src={value} style={{ maxWidth: '100px', maxHeight: '50px', }} />}

  </Box >
}