/**
 * Translate description: Edit page for banners
 */

import { useContext } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useLanguageContext } from "../../context/LanguageContext";
import AppProvider from '../../provider/AppProvider';
import AppContext from '../../context/AppContext';
import InfoContext from '../../context/InfoContext';
import { EditBannerContext } from './EditBannerContext';

import Bread from '../../components/layout/BreadCrumbs';
import Title from '../../components/layout/Title';
import { BannerForm, BannerSidebar } from './components/Form';

import { Box, Button } from '@mui/joy';
import Typography from '@mui/joy/Typography';

import { rethrow } from '../../utils/crud';
import { falseableToEmptyString, makeStates, setStates } from '../../utils/hooks/makeStates';
import { useEffectAsync } from '../../utils/hooks/useEffectAsync';
import { Loading } from '../../components/loading';
import { useInfo } from '../../utils/hooks/useInfo';
import { BannersApi } from '../../utils/crud/banner_api';


function prepreForUpdate(resource_states) {
    return {
        name: resource_states.name.trim(),
        image_url: resource_states.image_url.trim() || null,
        link_url: resource_states.link_url.trim() || null,
        code: resource_states.code.trim() || null,
        place: resource_states.place.trim() || null,
        placeholder_url: resource_states.placeholder_url.trim() || null,
        status_id: resource_states.status_id,
        lang_id: resource_states.lang_id,
    }
}
function prepreForCreate(resource_states) {
    return {
        name: resource_states.name.trim(),
        image_url: resource_states.image_url.trim() || null,
        link_url: resource_states.link_url.trim() || null,
        code: resource_states.code.trim() || null,
        place: resource_states.place.trim() || null,
        placeholder_url: resource_states.placeholder_url.trim() || null,
        status_id: resource_states.status_id,
        lang_id: resource_states.lang_id,
    }
}

function Edit() {
    const navigate = useNavigate();
    const info = useInfo();
    const { t, langs_db, lang_db: initial_lang_db } = useLanguageContext();
    const { id } = useParams();

    const { currentLangId } = useContext(AppContext);

    let title = id ? t("Edit banner") : t("New banner");

    const default_resource_state = {
        status_id: 0,
        status_name: "",
        name: '',
        image_url: '',
        link_url: '',
        placeholder_url: '',
        code: '',
        place: 'top',
        lang_id: currentLangId,
        allowed_langs: langs_db,
        language: initial_lang_db,
    }
    const default_secondary_state = {
        errors: {},
        load_error: false,
        loading: true,
    }

    const [secondary_state, secondary_setters] = makeStates(default_secondary_state);
    const { setLoading, setErrors } = secondary_setters;
    const { load_error, loading } = secondary_state;
    const [resource_states, resource_setters] = makeStates(default_resource_state)
    const { status_name, status_id } = resource_states;
    const { setInfo } = useContext(InfoContext);

    const handleResponseErrors = async (errors) => {
        if (!(errors.data instanceof Array)) {
            return setInfo({ show: true, type: 'danger', message: t("Unknown error") })
        }
        for (let error of errors.data) {
            if (error?.field) {
                setErrors(errors => ({ ...errors, [error.field]: error }))
            }
            else {
                console.error("Malformed error", errors, error)
            }
        }
    }

    const handleUpdateResponse = async (value) => {
        if (value.is_error) {
            handleResponseErrors(value.error)
        }
        else {
            await fetchData();
            setInfo({ show: true, type: 'success', message: "Updated" })
        }
        setTimeout(() => { setInfo({ show: false }) }, 6000)
    }
    const handleCreateResponse = async (value) => {
        if (value.is_error) {
            handleResponseErrors(value.error)
        }
        else {
            // await fetchData();
            if (!value.data.id) {
                return setInfo({ show: true, type: 'danger', message: "Unknown error" })
            }
            else {
                navigate(`/frontend/banner/${value.data.id}`)
                setInfo({ show: true, type: 'success', message: "Created" })
            }
        }
        setTimeout(() => { setInfo({ show: false }) }, 6000)
    }

    const fetchData = async () => {
        const _id = id
        let options = {}
        await crud.find(_id, options).then(rethrow)
            .then((data) => {
                setStates(resource_setters, data, falseableToEmptyString)
            })
            .catch((error) => {
                console.error(error);
                info(error.code == 404 ? t('Resource not found') : t('Unknown error'), 'danger')
                navigate(`/frontend/banners`)
            })
    }
    const crud = new BannersApi()
    useEffectAsync(async () => {
        setStates(secondary_setters, default_secondary_state);
        if (!id) {
            setStates(resource_setters, default_resource_state);
        }
        if (id) {
            await fetchData();
        }
        setLoading(false)
    }, [id]);


    let save_label = t('Save')
    let create_label = t('Create')
    let publish_label = t('Publish')
    let draft_label = t('Unpublish')
    let publish_button_label = status_name == 'draft' ? publish_label : draft_label
    let publish_button_color = status_name == 'draft' ? "primary" : "warning"
    let publish_button_variant = status_name == 'draft' ? "solid" : "outlined"
    let all_states = {
        ...resource_states,
        ...resource_setters,
        ...secondary_state,
        ...secondary_setters
    }
    if (loading) return <Loading />
    if (id && load_error) return <Navigate to={"/frontend/banners"} />
    return (
        <>
            <EditBannerContext.Provider value={{ ...all_states }}>
                <Bread path={[{ name: t('Banners'), path: '/frontend/banners' }, { name: title }]} />
                <Title>
                    <Typography level="h2" component="h1">
                        {title}
                    </Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                        {
                            id && <Button size='sm' variant="outlined" onClick={() => crud.update(id, prepreForUpdate(resource_states)).then(handleUpdateResponse)}>{save_label}</Button>
                        }
                        {
                            id && <Button size='sm' color={publish_button_color} variant={publish_button_variant} onClick={() => crud.status(id, status_id == 1 ? 2 : 1).then(handleUpdateResponse)}>{publish_button_label}</Button>
                        }
                        {
                            !id && <Button size='sm' variant="outlined" onClick={() => crud.create(prepreForCreate(resource_states)).then(handleCreateResponse)}>{create_label}</Button>
                        }
                    </Box>
                </Title>
                <Box sx={{ display: "flex", gap: 5, flexDirection: { xs: "column", lg: "row" } }}  >
                    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", flex: 4, gap: 1 }}>
                        <BannerForm />
                    </Box>
                    <BannerSidebar />
                </Box>
            </EditBannerContext.Provider>
        </>
    );
}

export function BannerEdit() {
    return (
        <AppProvider>
            <Edit />
        </AppProvider>
    );
}
