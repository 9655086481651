/**
 * Translate description: Form component for banners
 */
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useLanguageContext, langByCode } from "../../../context/LanguageContext";
import { EditBannerContext } from '../EditBannerContext';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Sheet from '@mui/joy/Sheet';


import { SelectSourceLang2 } from '../../../components/SelectSourceLang';
import { makeField } from '../../../components/makeField';
import { Option, Select, Textarea, Typography } from '@mui/joy';
import { UploadImage } from '../../../components/uploadImage';
import { capitalizeFirstLetter, updateErrors } from '../../../utils';

const Field = makeField(EditBannerContext)

export function BannerForm() {
    const { t } = useLanguageContext();

    const {
        name, setName,
        image_url, setImageUrl,
        link_url, setLinkUrl,
        code, setCode,
        placeholder_url, setPlaceholderUrl,
        place, setPlace,
        errors, setErrors,
    } = useContext(EditBannerContext)
    let name_label = t('Name')
    let image_url_label = t('Banner image')
    let link_url_label = t('Banner link')
    let code_label = t('Banner code')
    let placeholder_url_label = t('Placeholder image')
    let place_label = t('Place');
    return (
        <>
            <Field name="name" label={name_label} value={name} setter={setName} />
            <Field name="image_url" label={image_url_label} value={image_url} setter={setImageUrl} Element={UploadImage}
                elementSettings={{
                    onError: (error) => updateErrors("image_url", error, setErrors)
                }} />
            <Field name="link_url" label={link_url_label} value={link_url} setter={setLinkUrl} />
            <Field name="placeholder_url" label={placeholder_url_label} value={placeholder_url} setter={setPlaceholderUrl} Element={UploadImage}
                elementSettings={{
                    onError: (error) => updateErrors("placeholder_url", error, setErrors)
                }} />
            <Field name="code" label={code_label} value={code} setter={setCode} Element={Textarea} elementSettings={{ minRows: 2 }} />
            {/* <Field name="place" label={place_label} value={place} setter={setPlace} /> */}
            <Field name="place" label={place_label + ":"} value={place} setter={setPlace} Element={PlaceSelect} />
        </>
    )
}

function PlaceSelect({ value, onChange }) {
    const { t } = useLanguageContext();
    return <Select value={value} onChange={onChange}
        slotProps={{
            listbox: {
                placement: 'bottom-start'
            },
        }}>
        <Option value="top">{t('After Slider Desktop')}</Option>
        <Option value="top-mobile">{t('After Slider Mobile')}</Option>
        <Option value="aside">{t('Sidebar Desktop')}</Option>
        <Option value="mosaic">{t('Mosaic Home Desktop')}</Option>
        <Option value="mosaic-mobile">{t('Mosaic Home Mobile')}</Option>
        <Option value="publication">{t('Publication Desktop')}</Option>
        <Option value="publication-mobile">{t('Publication Mobile')}</Option>
        <Option value="footer">{t('Before Footer Desktop')}</Option>
        <Option value="footer-mobile">{t('Before Footer Mobile')}</Option>
    </Select>
}

export function BannerSidebar() {
    let { lang_code, id } = useParams();
    const { t } = useLanguageContext();
    const { errors, setErrors,
        status_name,
        allowed_langs,
        language, setLanguage
    } = useContext(EditBannerContext)
    const hasError = (fieldName) => fieldName in errors;
    let status_value_label = t(capitalizeFirstLetter(status_name || 'New'))
    let status_label = t('Status')
    let language_label = t('Language');

    const setLanguageCode = (code) => {
        setErrors({})
        setLanguage(langByCode(code))
    }
    useEffect(() => {
        if (lang_code) setLanguageCode(lang_code)
    }, [lang_code])

    return <Sheet style={{
        padding: 10,
        borderRadius: 5,
        minWidth: '250px',
        position: 'sticky',
        top: '56px',
        height: 'max-content'

    }}
        variant="outlined"
    >
        <FormControl sx={{ mb: 1 }}>
            <FormLabel sx={{ mb: 0 }}>{status_label}:</FormLabel>
            <Typography level="body-sm">{status_value_label}</Typography>
        </FormControl>
        <FormControl sx={{ mb: 1 }} error={hasError('language')}>
            <FormLabel >{language_label}: </FormLabel>
            <SelectSourceLang2
                languages={allowed_langs}
                currentLang={language?.code || 'en'}
                setCurrentLang={setLanguageCode}
                disabled={!!id}
            />
            <FormHelperText>{errors['language']?.message}</FormHelperText>
        </FormControl>

    </Sheet>
}
